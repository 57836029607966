import logo from './logo2.png';
import loading from './loading.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="logo" />
                <p>The new permanent url for Animoca Research Blog is:</p>
                <p><a href="https://research.dat.animoca.space">https://research.dat.animoca.space</a></p>
                <p>You will be redirected now!</p>
                <img src={loading} className="App-logo" alt="logo" />
            </header>
        </div>
    );
}

export default App;